import { store } from '../../store';

export const getUserToken = () => {
  const state = store.getState();
  return state.user.token;
};

export const getUserAuth = () => {
  const state = store.getState();
  return state.user.isAuth;
};

export const getUserInfo = () => {
  const state = store.getState();
  return state.user.userInfo;
};

export const getSelectedTemplateId = () => {
  const state = store.getState();
  return state.user.selectedTemplateId;
};

export const getSelectedTimeRange = () => {
  const state = store.getState();
  return state.user.selectedTimeRange;
};

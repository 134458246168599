import { Suspense, lazy } from 'react';
import { Route, HashRouter, Routes, Navigate } from 'react-router-dom';
import PageNotFound from '../components/PageNotFound';
import PageLoader from '../components/PageLoader';
import RequireAuth from '../hoc/CheckAuth';

const Login = lazy(() => import('../pages/Login'));
const Students = lazy(() => import('../pages/Students'));
const SendSms = lazy(() => import('../pages/SendSms'));
const CreateNewStudent = lazy(() => import('../pages/Students/CreateNewStudent'));

export default function RootRoutes() {
  return (
    <HashRouter>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/students" />} />
          <Route
            path="/students"
            element={
              <RequireAuth>
                <Students />
              </RequireAuth>
            }
          />

          <Route
            path="/students/new"
            element={
              <RequireAuth>
                <CreateNewStudent />
              </RequireAuth>
            }
          />

          <Route
            path="/students/edit/:id"
            element={
              <RequireAuth>
                <CreateNewStudent />
              </RequireAuth>
            }
          />

          <Route
            path="/send-sms"
            element={
              <RequireAuth>
                <SendSms />
              </RequireAuth>
            }
          />

          <Route path="/login" element={<Login />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

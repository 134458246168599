/* eslint-disable react/prop-types */
import { Navigate } from 'react-router-dom';
import AppLayout from '../components/AppLayout';
import { useSelector } from 'react-redux';
import { getUserAuth } from '../reducers/User/actions';

const RequireAuth = ({ children, noLayout = false }) => {
  const isAuthenticated = useSelector(getUserAuth);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return noLayout ? <>{children}</> : <AppLayout>{children}</AppLayout>;
};

export default RequireAuth;

import { Spin } from 'antd';
import React from 'react';

const PageLoader = () => {
  return (
    <div
      style={{
        height: '100vh',
        padding: '20px 50px',
        textAlign: 'center',
      }}
    >
      <Spin />
    </div>
  );
};

export default PageLoader;

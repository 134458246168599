import axios from 'axios';
import { getUserToken } from '../reducers/User/actions';
import { resetUser } from '../reducers/User/reducer';
import { store } from '../store';

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
// const apiVersion = process.env.REACT_APP_API_VERSION;
const apiEndPoint = `${apiUrl}`;

const axiosInstance = axios.create({
  baseURL: apiEndPoint,
});

async function get(url, params = {}) {
  const accessToken = getUserToken() || '';

  return axiosInstance
    .get(url, {
      params,
      headers: {
        'Content-Type': 'application/json',
        ...(accessToken && { Authorization: accessToken }),
      },
    })
    .then((response) => response.data)
    .catch(async (error) => {
      const responseStatus = error?.response?.status;
      if (responseStatus === 401) {
        store.dispatch(resetUser());
        window.location.href = '/#/login';
        return Promise.reject('Unauthorized access');
      }
      if (responseStatus >= 400 && responseStatus < 600) {
        return Promise.reject(error?.response?.data?.message || 'Something went wrong');
      }
      return Promise.reject(error);
    });
}

async function post(url, data = {}, options = {}) {
  const accessToken = getUserToken() || '';

  return axiosInstance
    .post(url, data, {
      headers: {
        ...(!options.formData && { 'Content-Type': 'application/json' }),
        ...(accessToken && { Authorization: accessToken }),
      },
    })
    .then((response) => response.data)
    .catch(async (error) => {
      const responseStatus = error?.response?.status;
      if (responseStatus === 401) {
        store.dispatch(resetUser());
        window.location.href = '/#/login';
        return Promise.reject('Unauthorized access');
      }
      if (responseStatus >= 400 && responseStatus < 600) {
        return Promise.reject(error?.response?.data?.message || 'Something went wrong');
      }
      return Promise.reject(error);
    });
}

async function deleteApi(url) {
  const accessToken = getUserToken() || '';

  return axiosInstance
    .delete(url, {
      headers: {
        ...{ 'Content-Type': 'application/json' },
        ...(accessToken && { Authorization: accessToken }),
      },
    })
    .then((response) => response.data)
    .catch(async (error) => {
      const responseStatus = error?.response?.status;
      if (responseStatus === 401) {
        store.dispatch(resetUser());
        window.location.href = '/#/login';
        return Promise.reject('Unauthorized access');
      }
      if (responseStatus >= 400 && responseStatus < 600) {
        return Promise.reject(error?.response?.data?.message || 'Something went wrong');
      }
    });
}

export default {
  get: get,
  post: (url, body, options) => post(url, body, options),
  delete: (url) => deleteApi(url),
};

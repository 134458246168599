import { createSlice } from '@reduxjs/toolkit';

export const user = createSlice({
  name: 'user',
  initialState: {
    isAuth: false,
    token: '',
  },
  reducers: {
    setAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    resetUser: (state) => {
      state.isAuth = false;
      state.token = '';
    },
  },
});

export const { setAuth, setToken, resetUser } = user.actions;

export default user.reducer;

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import User from './reducers/User/reducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, createMigrate } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  version: 4,
  migrate: createMigrate({
    4: (state) => {
      return {
        ...state,
        user: {
          isAuth: false,
          token: '',
        },
      };
    },
  }),
};

const rootReducer = combineReducers({
  user: User,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

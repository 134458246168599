/* eslint-disable react/prop-types */
import { Dropdown, Flex, Layout, Skeleton, Typography } from 'antd';
import SiderBox from './Sider';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo, getUserToken } from '../reducers/User/actions';
import api from '../utils/api';
import { resetUser } from '../reducers/User/reducer';
import { useState } from 'react';

const { Content, Header } = Layout;
const { Text, Title } = Typography;

const AppLayout = ({ children }) => {
  const userInfo = useSelector(getUserInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPageLoading, setPageLoading] = useState(false);
  const headerStyle = {
    height: 64,
    paddingInline: 48,
    lineHeight: '64px',
    backgroundColor: '#fff',
    paddingTop: 24,
    paddingBottom: 24,
  };

  const contentStyle = {
    padding: '24px',
    backgroundColor: '#F6F6FA',
    maxHeight: '100%',
    overflowY: 'auto',
  };

  const handleLogOut = () => {
    dispatch(resetUser());
    navigate('/login');
  };

  const items = [
    {
      key: '1',
      label: <span onClick={() => handleLogOut()}>Logout</span>,
    },
  ];

  return (
    <Layout style={{ height: '100%' }}>
      <SiderBox />

      <Layout>
        <Header style={headerStyle}>
          <Flex justify="end" align="center" style={{ height: '100%' }}>
            <Dropdown menu={{ items }} placement="bottomRight" arrow trigger={['click']}>
              <Flex gap={16} align="center" style={{ cursor: 'pointer' }}>
                <Flex vertical>
                  <Title level={5} style={{ margin: 0 }}>
                    Sri Aditya
                  </Title>
                </Flex>
                <DownOutlined />
              </Flex>
            </Dropdown>
          </Flex>
        </Header>
        {isPageLoading ? <Skeleton active /> : <Content style={contentStyle}>{children}</Content>}
      </Layout>
    </Layout>
  );
};

export default AppLayout;

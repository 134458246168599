import { ConfigProvider, Divider, Layout, Menu } from 'antd';
import { UserOutlined, MessageOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout;

const getItem = (label, key, icon, children, type, callback) => {
  return {
    key,
    icon,
    children,
    label,
    type,
    onClick: callback,
  };
};

const SiderBox = () => {
  const navigate = useNavigate();
  const current = window.location.pathname.split('/')[1];

  const items = [
    getItem('Students', 'students', <UserOutlined style={{ fontSize: 16 }} />, null, null, () => navigate('/students')),
    getItem('Send SMS', 'send-sms', <MessageOutlined style={{ fontSize: 16 }} />, null, null, () =>
      navigate('/send-sms'),
    ),
  ];

  return (
    <>
      <Sider width="17%" style={{ color: '#fff', backgroundColor: '#050E21' }}>
        <div style={{ height: 64 }} />
        <Divider style={{ margin: 0 }} />
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                darkItemBg: 'rgb(5, 14, 33)',
                darkItemColor: 'rgba(255, 255, 255, 0.65)',
                darkItemHoverBg: 'rgb(241, 248, 255, 0.08)',
                darkItemSelectedBg: 'rgb(241, 248, 255, 0.08)',
                darkSubMenuItemBg: 'rgb(5, 14, 33)',
              },
            },
          }}
        >
          <Menu selectedKeys={[current]} theme="dark" mode="inline" items={items} />
        </ConfigProvider>
      </Sider>
    </>
  );
};

export default SiderBox;

import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div style={{ height: '100vh' }}>
      <Result
        style={{ position: 'relative', width: '100%', top: '50%', transform: 'translate(0, -50%)' }}
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => navigate('/students')}>
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default PageNotFound;
